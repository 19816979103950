// src/constants/brand-api-scopes.ts
var BRAND_API_SCOPE_KEYS = [
  "members:read",
  "members:write",
  "magic_link:write",
  "member_groups:read",
  "member_groups:write",
  "posts:read",
  "rooms:read",
  "brand_products:read",
  "brand_products:write"
];
var BRAND_API_SCOPE_CONFIGS = {
  "members:read": {
    key: "members:read",
    description: "List members of the brand and their details."
  },
  "members:write": {
    key: "members:write",
    description: "Update details about members of the brand. "
  },
  "magic_link:write": {
    key: "magic_link:write",
    description: "Create a magic link for logging in. (only available to brands who use custom auth)"
  },
  "member_groups:read": {
    key: "member_groups:read",
    description: "List member groups and their details."
  },
  "member_groups:write": {
    key: "member_groups:write",
    description: "Create, update, and delete member groups."
  },
  "posts:read": {
    key: "posts:read",
    description: "Read posts and comments."
  },
  "rooms:read": {
    key: "rooms:read",
    description: "Read rooms and their details."
  },
  "brand_products:read": {
    key: "brand_products:read",
    description: "Read brand products and their details."
  },
  "brand_products:write": {
    key: "brand_products:write",
    description: "Create, update, and delete brand products."
  }
};

// src/constants/brand-webhook-event-types.ts
import { z } from "zod";
var BRAND_WEBHOOK_EVENT_TYPE_CONFIG = {
  USER_JOINED: {
    id: "USER_JOINED",
    name: "user.joined",
    description: "User joined the platform",
    payloadSchema: z.object({
      userId: z.string(),
      email: z.string().email().optional(),
      phoneNumber: z.string().optional()
    })
  },
  POST_CREATED: {
    id: "POST_CREATED",
    name: "post.created",
    description: "Post created",
    payloadSchema: z.object({
      postId: z.string(),
      userId: z.string(),
      title: z.string()
    })
  },
  POST_LIKED: {
    id: "POST_LIKED",
    name: "post.liked",
    description: "Post liked",
    payloadSchema: z.object({
      postId: z.string(),
      userId: z.string()
    })
  },
  POST_COMMENT_CREATED: {
    id: "POST_COMMENT_CREATED",
    name: "post_comment.created",
    description: "Post comment created",
    payloadSchema: z.object({
      postId: z.string(),
      postCommentId: z.string(),
      userId: z.string()
    })
  },
  POST_COMMENT_LIKED: {
    id: "POST_COMMENT_LIKED",
    name: "post_comment.liked",
    description: "Post comment liked",
    payloadSchema: z.object({
      postId: z.string(),
      postCommentId: z.string(),
      userId: z.string()
    })
  }
};
var BRAND_WEBHOOK_EVENT_TYPES = Object.values(
  BRAND_WEBHOOK_EVENT_TYPE_CONFIG
);

// src/constants/iron-session.ts
var IRON_SESSION_COOKIE_NAME = "addglow-session";

// src/constants/auth.ts
var PHONE_EMAIL_SUFFIX = "@phone.addglow.com";

// src/constants/brand-permissions.ts
var BRAND_PERMISSIONS = {
  // POSTS
  POST_DELETE_ANY: {
    description: "Delete a post",
    roomScoped: true
  },
  POST_PIN: {
    description: "Pin a post",
    roomScoped: true
  },
  POST_EDIT_SELF: {
    description: "Edit a post",
    roomScoped: true
  },
  POST_TAGS_EDIT_ANY: {
    description: "Edit tags",
    roomScoped: true
  },
  POST_CREATE_IN_READ_ONLY_ROOM: {
    description: "Create a post in a read-only room",
    roomScoped: true
  },
  POST_NOTIFY_ALL_USERS: {
    description: "Notify all users",
    roomScoped: true
  },
  POST_SCHEDULE_POST: {
    description: "Schedule a post",
    roomScoped: true
  },
  POST_WELCOME_USERS: {
    description: "Welcome users to the community",
    roomScoped: true
  },
  POST_HIGHLIGHT_POST: {
    description: "Highlight a post",
    roomScoped: true
  },
  // COMMENTS
  COMMENT_DELETE_ANY: {
    description: "Delete a comment",
    roomScoped: true
  },
  // ROOM
  ROOM_EDIT_POST_PROMPT: {
    description: "Edit the room prompt",
    roomScoped: true
  },
  ROOM_EDIT_ABOUT: {
    description: "Edit the room about",
    roomScoped: true
  },
  ROOM_EDIT_SETTINGS: {
    description: "Edit the room settings",
    roomScoped: false
  },
  ROOM_VIEW_MODERATOR_FILTER: {
    description: "Filter posts by post replies",
    roomScoped: true
  },
  // MANAGE ROOM MEMBERS
  ROOM_MEMBERS_REMOVE_MEMBER: {
    description: "Remove a member from a room",
    roomScoped: true
  },
  ROOM_MEMBERS_MAKE_ROOM_MODERATOR: {
    description: "Make a room moderator",
    roomScoped: true
  },
  ROOM_MEMBERS_REVOKE_ROOM_MODERATOR: {
    description: "Revoke room moderator status",
    roomScoped: true
  },
  // MEMBERS
  BRAND_MEMBERS_BAN_MEMBER: {
    description: "Ban a member from the community",
    roomScoped: false
  },
  BRAND_MEMBERS_MAKE_GLOBAL_MODERATOR: {
    description: "Make a member a global moderator",
    roomScoped: false
  },
  BRAND_MEMBERS_REVOKE_GLOBAL_MODERATOR: {
    description: "Revoke global moderator status",
    roomScoped: false
  },
  // CHALLENGE
  CHALLENGE_CREATE_EDIT_DELETE: {
    description: "Create, edit, delete challenges",
    roomScoped: false
  },
  CHALLENGE_ENTRY_DELETE_ANY: {
    description: "Delete a challenge entry",
    roomScoped: false
  },
  // SHELF
  SHELF_DESCRIPTION_DELETE_ANY: {
    description: "Delete a shelf productdescription",
    roomScoped: false
  },
  SHELF_TITLE_DELETE_ANY: {
    description: "Delete a shelf title",
    roomScoped: false
  },
  // EVENTS
  EVENTS_CREATE_EDIT_DELETE: {
    description: "Create, edit, delete events",
    roomScoped: false
  },
  // LINKS
  BRAND_LINKS_CREATE_EDIT_DELETE: {
    description: "Create, edit, delete links",
    roomScoped: false
  },
  BRAND_VIEW_CONTENT_REPORTS: {
    description: "View content reports",
    roomScoped: false
  },
  // PROFILE
  PROFILE_VIEW_SENSITIVE_INFO: {
    description: "View a profile with all information",
    roomScoped: false
  }
};
var BRAND_ROLE_TO_PERMISSIONS = {
  ADMIN: Object.keys(BRAND_PERMISSIONS),
  MODERATOR: [
    "POST_DELETE_ANY",
    "POST_PIN",
    "POST_TAGS_EDIT_ANY",
    "POST_CREATE_IN_READ_ONLY_ROOM",
    "POST_NOTIFY_ALL_USERS",
    "POST_WELCOME_USERS",
    "POST_HIGHLIGHT_POST",
    "POST_EDIT_SELF",
    "POST_SCHEDULE_POST",
    "COMMENT_DELETE_ANY",
    "BRAND_MEMBERS_BAN_MEMBER",
    "BRAND_MEMBERS_MAKE_GLOBAL_MODERATOR",
    "BRAND_MEMBERS_REVOKE_GLOBAL_MODERATOR",
    "ROOM_MEMBERS_REMOVE_MEMBER",
    "ROOM_MEMBERS_MAKE_ROOM_MODERATOR",
    "ROOM_MEMBERS_REVOKE_ROOM_MODERATOR",
    "ROOM_EDIT_POST_PROMPT",
    "ROOM_EDIT_ABOUT",
    "ROOM_EDIT_SETTINGS",
    "ROOM_VIEW_MODERATOR_FILTER",
    "CHALLENGE_CREATE_EDIT_DELETE",
    "CHALLENGE_ENTRY_DELETE_ANY",
    "SHELF_DESCRIPTION_DELETE_ANY",
    "SHELF_TITLE_DELETE_ANY",
    "EVENTS_CREATE_EDIT_DELETE",
    "BRAND_VIEW_CONTENT_REPORTS"
  ],
  ROOM_MODERATOR: [
    "POST_DELETE_ANY",
    "POST_PIN",
    "POST_TAGS_EDIT_ANY",
    "POST_CREATE_IN_READ_ONLY_ROOM",
    "POST_NOTIFY_ALL_USERS",
    "POST_SCHEDULE_POST",
    "POST_WELCOME_USERS",
    "POST_HIGHLIGHT_POST",
    "POST_EDIT_SELF",
    "COMMENT_DELETE_ANY",
    "ROOM_VIEW_MODERATOR_FILTER",
    "ROOM_MEMBERS_REMOVE_MEMBER",
    "ROOM_MEMBERS_MAKE_ROOM_MODERATOR",
    "ROOM_MEMBERS_REVOKE_ROOM_MODERATOR",
    "ROOM_EDIT_POST_PROMPT",
    "ROOM_EDIT_ABOUT",
    "BRAND_VIEW_CONTENT_REPORTS"
  ],
  CUSTOMER: []
};

// src/schemas/brand-member-badge.ts
import { z as z3 } from "zod";

// src/schemas/types.ts
import { z as z2 } from "zod";
var colorSchema = z2.string().regex(/^#[0-9a-fA-F]{6}$/);

// src/schemas/brand-member-badge.ts
var brandMemberBadgeTypeSchema = z3.enum([
  "GROUP",
  "ADMIN",
  "MODERATOR",
  "ROOM_MODERATOR"
]);
var updateBrandMemberBadgeSchema = z3.object({
  name: z3.string(),
  backgroundColor: colorSchema,
  foregroundColor: colorSchema
});
var brandMemberBadgeSchema = z3.object({
  name: z3.string(),
  type: brandMemberBadgeTypeSchema,
  memberGroupId: z3.string().optional(),
  backgroundColor: colorSchema,
  foregroundColor: colorSchema
}).refine((value) => {
  if (value.type === "GROUP" && !value.memberGroupId) {
    throw new Error("memberGroupId is required when type is GROUP");
  }
  if (value.type !== "GROUP" && value.memberGroupId) {
    throw new Error("memberGroupId should not be set when type is not GROUP");
  }
  return true;
});

// src/schemas/post.ts
import { z as z6 } from "zod";

// src/utils/dates.ts
import ct from "countries-and-timezones";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
  isSameDay,
  parseISO
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
function getTimezoneAbbreviation(timezone, date = /* @__PURE__ */ new Date()) {
  const options = {
    timeZone: timezone,
    timeZoneName: "short"
  };
  const formatter = new Intl.DateTimeFormat("en-US", options);
  const parts = formatter.formatToParts(date);
  const timeZoneName = parts.find((part) => part.type === "timeZoneName");
  if (!timeZoneName) return "";
  const match = timeZoneName.value.match(/\b([A-Z]{2,4})\b/);
  return match ? match[0] : timeZoneName.value;
}
function getUSTimezonesWithOffsets() {
  const timezones = ct.getTimezonesForCountry("US").map((tz) => {
    const offset = tz.utcOffset;
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    const offsetSign = offset >= 0 ? "+" : "-";
    const formattedOffset = `GMT${offsetSign}${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    return { label: `${formattedOffset} ${tz.name}`, value: tz.name };
  });
  timezones.unshift({ label: "GMT+00:00 UTC", value: "UTC" });
  return timezones;
}
var formatDateRangeToLocal = (start, end) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localStartDate = utcToZonedTime(new Date(start), userTimeZone);
  const localEndDate = end ? utcToZonedTime(new Date(end), userTimeZone) : null;
  const userTimezoneAbbr = getUserTimezoneAbbreviation();
  if (!localEndDate) {
    return `${format(
      localStartDate,
      "MMM do yyyy, h:mma"
    )} ${userTimezoneAbbr}`;
  }
  if (isSameDay(localStartDate, localEndDate)) {
    return `${format(localStartDate, "MMM do yyyy, h:mma")} - ${format(
      localEndDate,
      "h:mma"
    )} ${userTimezoneAbbr}`;
  }
  return `${format(localStartDate, "MMM do yyyy, h:mma")} - ${format(
    localEndDate,
    "MMM do yyyy, h:mma"
  )} ${userTimezoneAbbr}`;
};
function getUserTimezoneAbbreviation() {
  const userTimezone = getUserTimezone();
  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone: userTimezone,
    timeZoneName: "short"
  });
  const parts = formatter.formatToParts();
  const timeZoneName = parts.find((part) => part.type === "timeZoneName");
  return timeZoneName ? timeZoneName.value : userTimezone;
}
function getUserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
var formatZonedDateString = (dateString, timezone) => {
  if (!dateString || !timezone) return "";
  const zonedDate = utcToZonedTime(parseISO(dateString), timezone);
  return format(zonedDate, "yyyy-MM-dd");
};
var formatZonedDate = (date, timezone) => {
  if (!date || !timezone) return "";
  const zonedDate = utcToZonedTime(date, timezone);
  return format(zonedDate, "yyyy-MM-dd h:mm a");
};
var formatZonedTime = (dateString, timezone) => {
  if (!dateString || !timezone) return "";
  const zonedDate = utcToZonedTime(parseISO(dateString), timezone);
  return format(zonedDate, "h:mm a");
};
var formatTimeRemaining = (endDate) => {
  const now = /* @__PURE__ */ new Date();
  if (!endDate) {
    return null;
  }
  if (endDate < now) {
    return null;
  }
  const daysLeft = differenceInDays(endDate, now);
  if (daysLeft > 0) {
    return `Ends in ${daysLeft} day${daysLeft > 1 ? "s" : ""}`;
  }
  const hoursLeft = differenceInHours(endDate, now);
  if (hoursLeft > 0) {
    return `Ends in ${hoursLeft} hour${hoursLeft > 1 ? "s" : ""}`;
  }
  const minutesLeft = differenceInMinutes(endDate, now);
  return `Ends in ${minutesLeft} minute${minutesLeft > 1 ? "s" : ""}`;
};

// src/utils/domain.ts
function getFullCommunityDomain(domain) {
  return domain.match(/^(?:[a-zA-Z0-9-.]+\.)?localhost:\d+$/) ? `http://${domain}` : `https://${domain}`;
}

// src/utils/html.ts
function stripHtml(html) {
  return html.replace(/<p ?[^>]*>?/gm, " ").replace(/<[^>]*>?/gm, "").replace(/&nbsp;/g, " ").trim();
}
function validateHtmlContentLength(min, max) {
  return (html) => {
    const stripped = stripHtml(html);
    if (min && stripped.length < min) {
      return false;
    }
    if (max && stripped.length > max) {
      return false;
    }
    return true;
  };
}

// src/utils/auth.ts
var DAY_IN_MS = 1e3 * 60 * 60 * 24;
var SESSION_INACTIVITY_TIME = 30 * DAY_IN_MS;
var InvalidSessionError = class extends Error {
  constructor() {
    super("Invalid session");
  }
};
async function validateIronSessionData(session, { readOnly } = {}) {
  const ironSessionData = ironSessionDataSchema.safeParse(session);
  if (!ironSessionData.success) {
    throw new InvalidSessionError();
  }
  const validatedSession = session;
  if (validatedSession.token.source === "magic-link" && !validatedSession.brandId) {
    throw new InvalidSessionError();
  }
  if (!validatedSession.token.expiresAt || new Date(validatedSession.token.expiresAt) < /* @__PURE__ */ new Date()) {
    throw new InvalidSessionError();
  }
  if (new Date(validatedSession.token.expiresAt).getTime() - Date.now() < SESSION_INACTIVITY_TIME - DAY_IN_MS) {
    validatedSession.token.expiresAt = new Date(
      Date.now() + SESSION_INACTIVITY_TIME
    ).toISOString();
    if (!readOnly) {
      await validatedSession.save();
    }
  }
  return validatedSession;
}
var USER_ID_CLAIM = "https://app.com/user_id";
var EMAIL_CLAIM = "https://app.com/email";
var EMAIL_VERIFIED_CLAIM = "https://app.com/email_verified";
var ROLES_CLAIM = "https://app.com/roles";
var BRAND_ID_CLAIM = "https://app.com/brand_id";
var IS_FIRST_PARTY_CLAIM = "https://app.com/is_first_party";
async function getSessionDataFromAuth0Jwt(verifiedJwt, domain) {
  const userId = verifiedJwt[USER_ID_CLAIM];
  const roles = verifiedJwt[ROLES_CLAIM] ?? [];
  const email = verifiedJwt[EMAIL_CLAIM];
  const brandId = verifiedJwt[BRAND_ID_CLAIM];
  const isFirstParty = verifiedJwt[IS_FIRST_PARTY_CLAIM];
  const emailVerified = verifiedJwt[EMAIL_VERIFIED_CLAIM];
  if (!brandId && !isFirstParty) {
    throw new Error(`Brand ID is required for non-first-party tokens`);
  }
  if (!userId) {
    throw new Error(`Missing user id in JWT`);
  }
  if (!email) {
    throw new Error(`Missing email claim in JWT`);
  }
  return {
    user: {
      id: userId,
      roles,
      auth0Id: verifiedJwt.sub,
      email,
      emailVerified
    },
    token: {
      issuedAt: verifiedJwt.iat ? new Date(verifiedJwt.iat * 1e3).toISOString() : void 0,
      expiresAt: verifiedJwt.exp ? new Date(verifiedJwt.exp * 1e3).toISOString() : void 0,
      source: "auth0",
      sid: verifiedJwt.sid
    },
    brandId,
    domain
  };
}

// src/schemas/file-upload.ts
import { z as z4 } from "zod";
var fileUploadSchema = z4.object({
  id: z4.string().min(1),
  name: z4.string().nullish(),
  pendingUploadId: z4.string().nullish().transform(() => void 0),
  hostedUrl: z4.string().nullish().transform(() => void 0)
});

// src/schemas/post-attachment.ts
import { z as z5 } from "zod";
var postAttachmentInputSchema = z5.object({
  id: z5.string().nullish(),
  position: z5.number(),
  type: z5.enum(["IMAGE", "URL", "VIDEO"]),
  file: fileUploadSchema.nullish(),
  url: z5.string().nullish(),
  caption: z5.string().max(250).nullish()
});

// src/schemas/post.ts
var productReviewSchema = z6.object({
  brandProductId: z6.string(),
  rating: z6.number().min(1).max(5)
});
var MAX_POST_CHAR_COUNT = 5e3;
var MIN_POLL_CHOICES = 2;
var MAX_POLL_CHOICES = 4;
var pollSchema = z6.object({
  choices: z6.array(
    z6.object({
      id: z6.string().nullish(),
      pollId: z6.string().nullish(),
      title: z6.string().trim().min(1).max(500),
      position: z6.number().min(0).max(MAX_POLL_CHOICES),
      imageFile: fileUploadSchema.nullish()
    })
  ).min(MIN_POLL_CHOICES).max(MAX_POLL_CHOICES).superRefine((choices, ctx) => {
    const hasImage = choices.some((choice) => choice.imageFile);
    if (hasImage) {
      choices.forEach((choice, idx) => {
        if (!choice.imageFile) {
          ctx.addIssue({
            code: z6.ZodIssueCode.custom,
            message: "Each choice must include an image, or none of the choices should have images.",
            path: [idx, "imageFile"]
          });
        }
      });
    }
  }),
  votingEndsAt: z6.coerce.date().transform((d) => d.toISOString()).nullish()
});
var createOrEditPostInputSchema = z6.object({
  title: z6.string().trim().min(10).max(500),
  contentHtml: z6.string().refine(validateHtmlContentLength(0, MAX_POST_CHAR_COUNT), {
    message: `Content must be no more than ${MAX_POST_CHAR_COUNT} characters`
  }),
  attachments: z6.array(postAttachmentInputSchema).nullish(),
  tagIds: z6.array(z6.string()).nullish(),
  type: z6.enum(["TEXT", "PRODUCT_REVIEW", "POLL", "WELCOME"]),
  productReview: productReviewSchema.nullish(),
  poll: pollSchema.nullish(),
  publishedAt: z6.coerce.date().transform((d) => d.toISOString()).nullish(),
  shouldPublishLater: z6.boolean().nullish(),
  roomId: z6.string(),
  shouldNotifyMembers: z6.boolean().nullish(),
  postPromptId: z6.string().nullish()
});

// src/schemas/post-comment.ts
import { z as z7 } from "zod";
var MAX_COMMENT_CHAR_COUNT = 1500;
var postCommentAttachmentInputSchema = z7.object({
  id: z7.string().nullish(),
  position: z7.number(),
  type: z7.enum(["IMAGE", "URL", "VIDEO"]),
  file: fileUploadSchema.nullish(),
  url: z7.string().nullish(),
  caption: z7.string().max(250).nullish()
});
var createCommentInputSchema = z7.object({
  contentHtml: z7.string().refine(validateHtmlContentLength(1), {
    message: "Please enter a comment."
  }).refine(validateHtmlContentLength(0, MAX_COMMENT_CHAR_COUNT), {
    message: `The comment cannot exceed ${MAX_COMMENT_CHAR_COUNT} characters.`
  }),
  embeddedUrl: z7.string().url().nullish(),
  attachments: z7.array(postCommentAttachmentInputSchema).nullish()
});

// src/schemas/notification-setting.ts
import z8 from "zod";
var NotificationCategory = /* @__PURE__ */ ((NotificationCategory2) => {
  NotificationCategory2["Engagement"] = "ENGAGEMENT";
  NotificationCategory2["BrandContent"] = "BRAND_CONTENT";
  NotificationCategory2["Product"] = "PRODUCT";
  NotificationCategory2["Admin"] = "ADMIN";
  return NotificationCategory2;
})(NotificationCategory || {});
var notificationCategoryDescriptions = {
  ["ENGAGEMENT" /* Engagement */]: "Engagement with your content",
  ["BRAND_CONTENT" /* BrandContent */]: "Content from the brand",
  ["PRODUCT" /* Product */]: "Community feature updates",
  ["ADMIN" /* Admin */]: "Admin and moderator updates"
};
var notificationSettingKeys = [
  "postRepliesApp",
  "postRepliesEmail",
  "commentRepliesApp",
  "commentRepliesEmail",
  "mentionsApp",
  "mentionsEmail",
  "likesApp",
  "postPollEmail",
  "postPollApp",
  "digestsEmail",
  "challengeEmail",
  "eventEmail",
  "postEmail",
  "loyaltyPointReminderEmail",
  "onboardingEmail",
  "adminNewSupportPostEmail",
  "adminUnrespondedPostEmail"
];
var NOTIFICATION_CONFIG = {
  postRepliesApp: {
    channel: "app",
    label: "Comments on My Posts",
    category: "ENGAGEMENT" /* Engagement */
  },
  postRepliesEmail: {
    channel: "email",
    label: "Comments on My Posts",
    category: "ENGAGEMENT" /* Engagement */
  },
  commentRepliesApp: {
    channel: "app",
    label: "Replies to My Comments",
    category: "ENGAGEMENT" /* Engagement */
  },
  commentRepliesEmail: {
    channel: "email",
    label: "Replies to My Comments",
    category: "ENGAGEMENT" /* Engagement */
  },
  mentionsApp: {
    channel: "app",
    label: "Mentions",
    category: "ENGAGEMENT" /* Engagement */
  },
  mentionsEmail: {
    channel: "email",
    label: "Mentions",
    category: "ENGAGEMENT" /* Engagement */
  },
  likesApp: {
    channel: "app",
    label: "Likes",
    category: "ENGAGEMENT" /* Engagement */
  },
  postPollEmail: {
    channel: "email",
    label: "New Poll",
    category: "BRAND_CONTENT" /* BrandContent */
  },
  postPollApp: {
    channel: "app",
    label: "New Poll",
    category: "BRAND_CONTENT" /* BrandContent */
  },
  digestsEmail: {
    channel: "email",
    label: "Top Posts Digest",
    category: "BRAND_CONTENT" /* BrandContent */
  },
  challengeEmail: {
    channel: "email",
    label: "New Challenge",
    category: "BRAND_CONTENT" /* BrandContent */
  },
  eventEmail: {
    channel: "email",
    label: "New Event",
    category: "BRAND_CONTENT" /* BrandContent */
  },
  postEmail: {
    channel: "email",
    label: "New Post From Brand",
    category: "BRAND_CONTENT" /* BrandContent */
  },
  loyaltyPointReminderEmail: {
    channel: "email",
    label: "Loyalty Point Reminder",
    category: "BRAND_CONTENT" /* BrandContent */
  },
  onboardingEmail: {
    channel: "email",
    label: "Onboarding Series",
    category: "PRODUCT" /* Product */
  },
  adminNewSupportPostEmail: {
    channel: "email",
    label: "New Post In Support Room",
    category: "ADMIN" /* Admin */
  },
  adminUnrespondedPostEmail: {
    channel: "email",
    label: "Posts With No Engagement",
    category: "ADMIN" /* Admin */
  }
};
var DEFAULT_NOTIFICATION_SETTING = Object.fromEntries(
  Object.entries(NOTIFICATION_CONFIG).map(([key]) => [key, true])
);
var notificationSettingInputSchema = z8.record(
  z8.enum(notificationSettingKeys),
  z8.boolean().nullish()
);

// src/schemas/iron-session.ts
import { z as z9 } from "zod";
var ironSessionDataSchema = z9.object({
  user: z9.object({
    id: z9.string(),
    auth0Id: z9.string().nullish(),
    customAuthSub: z9.string().nullish(),
    roles: z9.array(z9.string()),
    email: z9.string(),
    emailVerified: z9.boolean().optional()
  }),
  token: z9.object({
    source: z9.enum(["auth0", "magic-link", "grant-auth0", "grant-custom"]),
    issuedAt: z9.string().datetime().optional(),
    expiresAt: z9.string().datetime().optional(),
    notes: z9.string().optional(),
    // Auth0 session ID from token used for logging out
    sid: z9.string().optional()
  }),
  domain: z9.string().optional(),
  brandId: z9.string().optional(),
  originalUserId: z9.string().optional()
});
export {
  BRAND_API_SCOPE_CONFIGS,
  BRAND_API_SCOPE_KEYS,
  BRAND_PERMISSIONS,
  BRAND_ROLE_TO_PERMISSIONS,
  BRAND_WEBHOOK_EVENT_TYPES,
  BRAND_WEBHOOK_EVENT_TYPE_CONFIG,
  DEFAULT_NOTIFICATION_SETTING,
  IRON_SESSION_COOKIE_NAME,
  InvalidSessionError,
  MAX_COMMENT_CHAR_COUNT,
  MAX_POLL_CHOICES,
  MAX_POST_CHAR_COUNT,
  MIN_POLL_CHOICES,
  NOTIFICATION_CONFIG,
  NotificationCategory,
  PHONE_EMAIL_SUFFIX,
  SESSION_INACTIVITY_TIME,
  brandMemberBadgeSchema,
  brandMemberBadgeTypeSchema,
  createCommentInputSchema,
  createOrEditPostInputSchema,
  formatDateRangeToLocal,
  formatTimeRemaining,
  formatZonedDate,
  formatZonedDateString,
  formatZonedTime,
  getFullCommunityDomain,
  getSessionDataFromAuth0Jwt,
  getTimezoneAbbreviation,
  getUSTimezonesWithOffsets,
  getUserTimezone,
  getUserTimezoneAbbreviation,
  ironSessionDataSchema,
  notificationCategoryDescriptions,
  notificationSettingInputSchema,
  notificationSettingKeys,
  postAttachmentInputSchema,
  postCommentAttachmentInputSchema,
  stripHtml,
  updateBrandMemberBadgeSchema,
  validateHtmlContentLength,
  validateIronSessionData
};
